<template>
  <div>
    <ManageRegisterMaster />
  </div>
</template>
<script>
import ManageRegisterMaster from "@/components/ManageRegisterMaster/ManageRegisterMaster";
export default {
  components: {
    ManageRegisterMaster,
  },
  created() {},
};
</script>
